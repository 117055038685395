export const LOGO_HEADER_URL =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/logo-company/logo-adamftd-header.webp';
export const LOGO_LOGIN_URL = 'https://p8q4.c5.e2-10.dev/images/front-end/images/logo-company/logo-adamftd-login.webp';
export const LOGO_INVOICE_URL = 'https://p8q4.c5.e2-10.dev/images/front-end/images/logo-company/logo-invoice.svg';
export const LOGO_COMPANY_DEFAULT_URL =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/logo-company/logo-company-default.svg';
export const LOGO_GOOGLE_URL = 'https://p8q4.c5.e2-10.dev/images/front-end/images/logo-social/google.png';
export const LOGO_TRUSTPILOT_URL = 'https://p8q4.c5.e2-10.dev/images/front-end/images/logo-social/trustpilot.png';
export const LOGO_YELP_URL = 'https://p8q4.c5.e2-10.dev/images/front-end/images/logo-social/yelp.png';
export const BACKGROUND_LOGIN = 'https://p8q4.c5.e2-10.dev/images/front-end/images/background-login.webp';
export const BANNER_NOTIFY_HOME_PAGE =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/banner-notify-home-page.webp';
export const ICON_BANNER_HOME_PAGE =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-banner-home-page.webp';
export const ICON_SUPPORT_NO_DATA_COMPANY =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-support-no-data-company.webp';
export const ICON_QUICK_TIP_SEARCH_COMPANY =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-quick-tips-search-company.webp';
export const ICON_NO_MATCH_SEARCH_COMPANY =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-no-matching-search-company.webp';
export const ICON_NO_DATA_COMPANY = 'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-no-data-company.webp';
export const ICON_NO_PLAN_AND_USAGE =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-no-plan-and-usage.webp';
export const ICON_DISCLAIMER_COMPANY_DATA =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-disclaimer-company-data.webp';
export const ICON_PAID_TO_UNBLOCK_CONTENT =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-paid-to-unblock-content.webp';
export const ICON_DO_NOT_HAVE_ENOUGH_CREDIT =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-do-not-have-enough-credit.webp';
export const ICON_UPGRADE_PLAN = 'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-upgrade-plan.webp';
export const ICON_CONFIRM_GET_TRIAL =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-confirm-get-trial.webp';
export const ICON_WALLET_PRICING_PLAN =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/icon-wallet-pricing-plan.webp';
export const BANNER_BOOKING_DEMO_CALENDAR =
  'https://p8q4.c5.e2-10.dev/images/front-end/images/icons/banner-booking-demo-calendar.webp';

export const DEFAULT_AVATARS = [
  'https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar1.webp',
  'https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar2.webp',
  'https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar3.webp',
  'https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar4.webp',
  'https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar5.webp',
  'https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar6.webp',
  // "https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar7.webp",
  // "https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar8.webp",
  // "https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar9.webp",
  // "https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar10.webp",
  // "https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar11.webp",
  // "https://p8q4.c5.e2-10.dev/images/front-end/images/avatar-user-default/avatar12.webp"
] as Array<string>;

export const TARIFFS_AND_RULE_DEFAULT_PAGE_IMAGES = {
  shipping_blue_box: 'https://p8q4.c5.e2-10.dev/images/front-end/images/tariffs-and-rules-page/shipping_blue_box.webp',
  avoid_costly_penalties:
    'https://p8q4.c5.e2-10.dev/images/front-end/images/tariffs-and-rules-page/avoid_costly_penalties.webp',
  optimize_market_entry:
    'https://p8q4.c5.e2-10.dev/images/front-end/images/tariffs-and-rules-page/optimize_market_entry.webp',
  enhance_supply_chain_efficiency:
    'https://p8q4.c5.e2-10.dev/images/front-end/images/tariffs-and-rules-page/enhance_supply_chain_efficiency.webp',
  international_money_transfer:
    'https://p8q4.c5.e2-10.dev/images/front-end/images/tariffs-and-rules-page/international-money-transfer.webp',
  saving_money_bank: 'https://p8q4.c5.e2-10.dev/images/front-end/images/tariffs-and-rules-page/saving-money-bank.webp',
  supply_chain_distribution:
    'https://p8q4.c5.e2-10.dev/images/front-end/images/tariffs-and-rules-page/supply-chain-distribution.webp',
};

export const AI_HS_CODE_LABELING_IMAGES = {
  banner:
    'https://p8q4.c5.e2-10.dev/images/front-end%2Fimages%2Fai-hs-code-labeling%2FHS%20Code%20Labeling%20Banner.webp',
  single_prediction_cover:
    'https://p8q4.c5.e2-10.dev/images/front-end%2Fimages%2Fai-hs-code-labeling%2FSingle%20prediction%20cover.webp',
};
