import { Box } from '@mui/material';
import ImageComponent from 'src/components/common/Image';
import { LOGO_HEADER_URL } from 'src/utils/constants/imagesUrl';

const GlobalLoading = () => {
  return (
    <Box
      display={'grid'}
      sx={{ placeItems: 'center', animation: 'ease-in-out', animationDuration: 300 }}
      width={'100dvw'}
      height={'100dvh'}
    >
      <ImageComponent imgSrc={LOGO_HEADER_URL} width={270} height={200} />
    </Box>
  );
};

export default GlobalLoading;
