import { LOGO_HEADER_URL } from 'src/utils/constants/imagesUrl';

interface IProps {
  imgSrc: string;
  width?: string | number;
  height?: string | number;
  style?: object;
  fallbackImage?: string;
}

const ImageComponent = ({ imgSrc, width, height, style = {}, fallbackImage = LOGO_HEADER_URL }: IProps) => {
  return (
    <img
      srcSet={imgSrc}
      src={imgSrc}
      width={width || 'auto'}
      height={height || 'auto'}
      loading="lazy"
      style={style}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = fallbackImage;
        currentTarget.srcset = fallbackImage;
      }}
    />
  );
};

export default ImageComponent;
