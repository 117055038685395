import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';

// Components
import reportWebVitals from './reportWebVitals';

// Utilities
import React from 'react';
import ErrorBoundary from 'src/components/ErrorBoundary';
import GlobalLoading from 'src/components/GlobalLoading';
import SnackbarGlobal from 'src/components/common/Snackbar';
import { queryClient } from 'src/configs/queryClient';
import StripeProvider from 'src/contexts/StripeProvider';
import ThemeCustomization from 'src/themes';
import 'src/sentry';
import './index.css';

const LazyLoadingApp = React.lazy(() => import('src/App'));
const SentryApp = Sentry.withProfiler(LazyLoadingApp);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.Suspense fallback={<GlobalLoading />}>
    <Sentry.ErrorBoundary fallback={<p>Đã xảy ra lỗi! Vui lòng thử lại sau.</p>}>
      <ErrorBoundary>
        <ThemeCustomization>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <StripeProvider>
                <QueryClientProvider client={queryClient}>
                  {(process.env.NODE_ENV === 'development' && (
                    <>
                      <SentryApp />
                      <ReactQueryDevtools initialIsOpen={false} />
                    </>
                  )) || <SentryApp />}
                  <SnackbarGlobal />
                </QueryClientProvider>
              </StripeProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeCustomization>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  </React.Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
