import * as Sentry from '@sentry/react';
import React from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  /** TRACING */
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  profilesSampleRate: 1.0, // This sets the sample rate to be 100%. You may want this to be 100% while in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1, // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV, // 'development', 'production', 'staging'...
  // debug: process.env.NODE_ENV === 'development', // Bật debug để kiểm tra log trong quá trình phát triển
});
